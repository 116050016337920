<template>
  <b-row>

    <b-col md="12">
      <b-card title="Liste des utilisateurs" no-body>

        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="float-right mb-1"
                onclick="history.back()">
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group label="Trier" label-size="sm" label-align-sm="left" label-cols-sm="2" label-for="sortBySelect"
              class=" mb-md-0">
              <b-input-group size="sm">
                <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions">
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group label="Filtrer" label-cols-sm="2" label-align-sm="left" label-size="sm" label-for="filterInput"
              class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Rechercher" />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>
        <b-table striped hover responsive small :busy="loader" class="position-relative" :per-page="perPage"
          :current-page="currentPage" :items="users" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner style="width: 2rem; height: 2rem;" variant="primary" label="Large Spinner" type="grow"
                class="mt-2" />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(role)="data">
            <b-badge pill :variant="`${resolveUserRoleVariant(data.item.role)}`">
              {{ data.item.role }}
            </b-badge>
          </template>
          <template #cell(status)="data">
            <b-badge pill :variant="`${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item v-if="data.item.role === 'Structure'" v-b-modal.details-modal
                @click="getDetails(data.item.structure)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Détails</span>
              </b-dropdown-item>

              <b-dropdown-item v-b-modal.validation @click="getItem('ENABLED', data.item)">
                <feather-icon icon="UserCheckIcon" class="text-success" />
                <span class="align-middle ml-50 text-success">Valider</span>
              </b-dropdown-item>

            </b-dropdown>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group label="Par Page" label-cols="6" label-align="left" label-size="sm" label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination v-model="currentPage" :total-rows="users.length" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0 pagination-center">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
      <b-modal id="validation" ref="my-modal" cancel-title="Non" ok-title="Oui" title="Validation de compte"
        modal-class="modal-success" ok-variant="success" button-size="sm" cancel-variant="outline-secondary"
        @ok="handleOk">
        <form @submit.prevent="handleSubmitModal">
          <h6>Etes-vous sûr de vouloir valider ce compte?</h6>
        </form>
      </b-modal>
    </b-col>
    <b-modal id="details-modal" ref="my-modal" button-size="sm" title="Details supplementaires" ok-only size="lg" ok-title="Fermer">
      <b-card no-body>
        <b-card-body>
          <b-list-group v-if="details">
            <b-list-group-item class="d-flex justify-content-between align-items-center" active>
              <span>Nom de la structure</span>
              <feather-icon icon="CopyIcon" class="cursor-pointer" @click="copyURL(details.nom)" />
            </b-list-group-item>
            <main>
              <pre style="background: black;">
                <code class="language-css">
                <div class="ml-2">{{ details.nom }}</div>
              </code></pre>
            </main>
            <b-list-group-item class="d-flex justify-content-between align-items-center" active>
              <span>DN du certificat</span>
              <feather-icon icon="CopyIcon" class="cursor-pointer" @click="copyURL(details.dn)" />
            </b-list-group-item>
            <main>
              <pre style="background: black;">
                <code class="language-css">
                <div class="ml-2">{{ details.dn }}</div>
              </code></pre>
            </main>
            <b-list-group-item class="d-flex justify-content-between align-items-center" active>
              <span>Commande de generation du certificat</span>
              <feather-icon icon="CopyIcon" class="cursor-pointer" @click="copyURL(details.commande)" />
            </b-list-group-item>
            <main>
              <pre style="background: black;">
                <code class="language-css">
                <div class="ml-2">{{ details.commande }}</div>
              </code></pre>
            </main>
            <b-list-group-item class="d-flex justify-content-between align-items-center" active>
              <span>Nom du worker</span>
              <feather-icon icon="CopyIcon" class="cursor-pointer" @click="copyURL(details.worker)" />
            </b-list-group-item>
            <main>
              <pre style="background: black;">
                <code class="language-shell">
                <div class="ml-2">{{ details.worker }}</div>
              </code></pre>
            </main>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive,
} from '@vue/composition-api'
import useAlertNotification from '@/services/notification/useAlert'
import {
  BRow, BCol, BCard, BTable, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BFormInput,
  BBadge, BSpinner, BModal, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'
// eslint-disable-next-line import/extensions, import/no-unresolved
import BCardCode from '@core/components/b-card-code'
import Prism from 'vue-prism-component'

export default {
  components: {
    BRow,
    Prism,
    BListGroup,
    BListGroupItem,
    BCol,
    BModal,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BCardCode,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BBadge,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      users, getListeUserStatus, loader, changerStatusCompte, userSuccess,
    } = useAuthUser()
    const { successToast, errorToast } = useAlertNotification()

    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }
    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'lastname', label: 'Nom', sortable: true },
      { key: 'firstname', label: 'Prénom', sortable: true },
      { key: 'telephone', label: 'Téléphone', sortable: true },
      { key: 'role', label: 'Role', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'status', label: 'Status Compte', sortable: true },
      { key: 'actions' },
    ]
    const form = reactive({
      status: '',
      id: null,
    })
    /* eslint-disable global-require */
    const items = users

    onMounted(async () => {
      await getListeUserStatus()
      // Set the initial number of items
      loader.value = false
      totalRows.value = items.length
    })
    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }
    const clearForm = () => {
      form.status = ''
      form.role = ''
      form.id = null
    }
    const changerStatus = async () => {
      await changerStatusCompte({ ...form })
      if (userSuccess.value === true) {
        clearForm()
        console.clear()
      }
    }
    // Obtenir un user
    const getItem = async (state, item) => {
      form.id = item.id
      form.role = item.role === 'Structure' ? 'ROLE_STRUCTURE' : 'ROLE_ADMIN'
      form.status = state
    }
    const resolveUserStatusVariant = status => {
      if (status === 'CREATED') return 'warning'
      if (status === 'ENABLED') return 'success'
      return 'primary'
    }
    const resolveUserRoleVariant = role => {
      if (role === 'Administrateur') return 'primary'
      if (role === 'Structure') return 'warning'
      return 'primary'
    }

    const formatter = value => value.toUpperCase()
    return {
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,
      onFiltered,
      changerStatus,
      users,
      form,
      successToast,
      errorToast,
      getItem,
      formatter,
      loader,
      resolveUserStatusVariant,
      resolveUserRoleVariant,
    }
  },
  data() {
    return { details: {} }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {
    getDetails(item) {
      const cn = this.generateUniqueString()
      const nom = item.O.split(' ').join('_')
      this.details = {
        nom: item.O,
        dn: `CN=${cn},OU=${item.OU},O=${item.O},C=${item.C}`,
        worker: `eCEV-Signer-${nom}-${item.id}`,
        commande: `openssl x509 -req -in ./CSR/csr${item.id+1}.p10 -CA CAcert.pem -CAkey CAprivkey.key -CAcreateserial -days 730 -extensions ext -extfile DSC.conf -out ./CERTS/${cn}.pem`,
      }
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        this.successToast('Copied')
      } catch ($e) {
        this.errorToast('Cannot copy')
      }
    },
    generateUniqueString() {
      const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';

      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }

      return result;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },

    async handleSubmitModal() {
      // Exit when the form isn't valid
      await this.changerStatus()
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },

  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
